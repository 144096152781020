<template>
  <div>
    <div
      v-if="todayHours"
      class="d-flex justify-space-between align-center font-weight-bold black--text today-title"
    >
      <span class="week-day">{{ todayHours.weekDay }}</span>
      <span
        class="hours"
        v-if="todayHours.hours"
        v-html="todayHours.hours"
      ></span>
    </div>
    <div v-if="otherDayHours && otherDayHours.length > 0">
      <div
        class="d-flex justify-space-between align-center"
        v-for="day in otherDayHours"
        :key="day.weekDay"
      >
        <span class="week-day">{{ day.weekDay }}</span>
        <v-spacer />
        <span class="hours" v-if="day.hours" v-html="day.hours"></span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.hours {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    .partial-hours:not(:last-child) {
      margin-right: 4px;
      &::after {
        content: ",";
      }
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
</style>
<script>
import { calculateHourRow } from "@/warehouseHoursHelper";

export default {
  name: "WarehouseHours",
  props: {
    serviceHours: { type: Object, required: true }
  },
  data() {
    return {
      todayHours: null,
      otherDayHours: [],
      offsetDay: 1
    };
  },
  methods: {},
  mounted() {
    // day of week from server: 1 sunday, 7 saturday
    // dayJs: 0 sunday, 6 saturday
    let today = this.$dayjs();
    let todayHours = calculateHourRow(this.serviceHours, today, this.offsetDay);

    this.todayHours = {
      weekDay: today.format("dddd"),
      hours: todayHours ? todayHours : "Chiuso"
    };
    for (let i = 0; i < 6; i++) {
      let dayToCalc = this.$dayjs().add(i + 1, "day");
      let otherDay = calculateHourRow(
        this.serviceHours,
        dayToCalc,
        this.offsetDay
      );
      this.otherDayHours.push({
        weekDay: dayToCalc.format("dddd"),
        hours: otherDay ? otherDay : this.$t("warehouse.closed")
      });
    }
  }
};
</script>
