<template>
  <div class="is-open-right-now py-3 d-flex align-center">
    <span class="text-h1" :class="color">{{ label }}</span>
    <span class="text-sub ml-3 mt-1" v-if="actualHourSlot">
      Chiude alle {{ actualHourSlot.endHour }}</span
    >
  </div>
</template>
<style lang="scss" scoped>
.is-open-right-now {
  .text-h1 {
    line-height: 2rem;
  }
  .text-sub {
    font-weight: normal !important;
  }
}
</style>
<script>
import {
  isOpenRightNow,
  calculateActualHourSlot
} from "@/warehouseHoursHelper";

export default {
  name: "isOpenRightNow",
  props: {
    serviceHours: { type: Object, required: true },
    small: { type: Boolean, required: false, default: false },
    maxWidth: { type: String, required: false, default: "80" },
    outlined: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      open: false,
      label: null,
      alertColor: null,
      offsetDay: 1,
      actualHourSlot: null,
      color: "primary--text"
    };
  },
  mounted() {
    this.open = isOpenRightNow(this.serviceHours, this.offsetDay);

    this.label = this.open
      ? this.$t("warehouse.open")
      : this.$t("warehouse.closed");

    this.color = this.open ? "green--text text--darken-2" : "primary--text";

    this.actualHourSlot = calculateActualHourSlot(
      this.serviceHours,
      this.offsetDay
    );
  }
};
</script>
