<template>
  <!-- <Breadcrumb :items="breadcrumb" /> -->
  <div class="warehouse-detail" v-if="warehouse">
    <!-- <h1
      class="d-inline grey lighten-3 px-2"
      v-html="$t('warehouse.detail.title', { warehouse: warehouse.name })"
    ></h1> -->

    <!-- <v-divider class="custom-divider"></v-divider> -->
    <v-container>
      <router-link
        :to="'/negozi-e-volantini'"
        class="d-flex align-center py-2 mb-6 go-back font-weight-bold"
      >
        <v-icon color="primary">$chevronLeft</v-icon>
        {{ $t("warehouse.detail.goBack") }}
      </router-link>

      <v-img
        v-if="warehouse.metaData?.warehouse_locator?.COVER"
        class="my-4 rounded-md"
        :src="warehouse.metaData.warehouse_locator.COVER.location"
      ></v-img>

      <v-row class="my-4">
        <v-col cols="12" md="6">
          <div class="warehouse-detail-column">
            <h2 class="warehouse-title">{{ warehouse.address.addressName }}</h2>
            <div>
              <isOpenRightNow
                :serviceHours="warehouse.serviceHours"
              ></isOpenRightNow>
            </div>
            <div class="hourse-table pb-3">
              <WarehouseHours
                :serviceHours="warehouse.serviceHours"
              ></WarehouseHours>
            </div>

            <div class="phone-number pb-2">
              {{ $t("warehouse.detail.phone") }}
              <a
                class="default--text text-no-decoration"
                :href="
                  `tel:${$ebsn.meta(warehouse, 'warehouse_locator.PHONE')}`
                "
                >{{ $ebsn.meta(warehouse, "warehouse_locator.PHONE") }}</a
              >
            </div>

            <div class="warehouse-address pb-2">
              {{ warehouse.address.addressName }}<br />
              {{ warehouse.address.address1 }}
              {{
                warehouse.address.addressNumber
                  ? ", " + warehouse.address.addressNumber
                  : null
              }}, {{ warehouse.address.city }} ({{
                warehouse.address.province
              }})
            </div>

            <div class="warehouse-directions pb-2">
              <v-btn
                @click="clicked"
                color="primary"
                class="my-2"
                depressed
                :href="
                  `https://www.google.it/maps/dir//${warehouse.name}/@${warehouse.address.latitude},${warehouse.address.longitude}`
                "
                target="_blank"
                >{{ $t("warehouse.detail.directions") }}</v-btn
              >
            </div>
          </div>
        </v-col>
        <!-- <v-col cols="12" md="6">
          <v-card outlined elevation="0" class="maps-card rounded-md">
            <GmapMap
              v-if="warehouse.warehouseId"
              ref="mapRef"
              :center="{
                lat: warehouse.address.latitude,
                lng: warehouse.address.longitude
              }"
              :zoom="15"
              :style="{ width: mapWidth, height: mapHeight }"
              :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: false
              }"
            >
              <GmapMarker
                :ref="warehouse.warehouseId"
                :position="
                  google &&
                    new google.maps.LatLng(
                      warehouse.address.latitude,
                      warehouse.address.longitude
                    )
                "
                :clickable="true"
                icon="/img_layout/map/negozi.png"
              />
            </GmapMap>
          </v-card>
        </v-col> -->
      </v-row>

      <v-divider class="my-6"></v-divider>

      <flyer-slider
        :warehouse-id="warehouse.warehouseId"
        :disableFlyer="disableFlyer"
      ></flyer-slider>

      <v-divider class="my-6" v-if="!disableFlyer"></v-divider>

      <div @click="onLinkClick">
        <ebsn-meta
          :target="warehouse"
          path="warehouse_locator.DESCRIPTION"
          tag="div"
        />
      </div>

      <v-divider class="my-6"></v-divider>

      <div class="warehouse-groups">
        <div
          class="warehouse-group py-4"
          v-for="service in services"
          :key="service.warehouseClassGroupId"
        >
          <h2 class="mb-4">{{ service.name }}</h2>
          <v-row class="warehouse-services py-2">
            <v-col
              cols="4"
              class="warehouse-service"
              v-for="serviceClass in service.services"
              :key="serviceClass.index"
            >
              <v-img
                :src="serviceClass.iconSource"
                :alt="serviceClass.description"
                :title="serviceClass.description"
                class="pdv-service-img rounded pa-2"
              />
              <span>{{ serviceClass.name }}</span>
            </v-col>
          </v-row>
          <v-divider class="mt-4 mb-2"></v-divider>
        </div>
      </div>

      <div
        class="warehouse-gallery my-4"
        v-if="warehouse.metaData?.warehouse_locator?.GALLERY"
      >
        <h2 class="mb-4">{{ $t("warehouse.detail.activeGalleryStores") }}</h2>
        <!-- <v-divider class="mt-2 mb-4"></v-divider> -->
        <div v-html="warehouse.metaData?.warehouse_locator?.GALLERY"></div>
      </div>

      <div class="near-warehouses my-4" v-if="nearWarehouses">
        <h2 class="mb-4">Altri negozi nelle vicinanze</h2>
        <v-row class="container">
          <swiper :options="swiperOptions" ref="swiperRef" class="w-100 h-100">
            <swiper-slide
              class="h-auto"
              v-for="warehouse in nearWarehouses"
              :key="warehouse.warehouseId"
            >
              <v-card class="d-flex flex-column h-100">
                <v-card-title>
                  {{ warehouse.address.addressName }}
                </v-card-title>
                <v-card-text>
                  <isOpenRightNow
                    :serviceHours="warehouse.serviceHours"
                  ></isOpenRightNow>
                  <div class="near-warehouse-address-info my-2">
                    {{ warehouse.address.addressName }}<br />
                    {{ warehouse.address.address1 }}
                    {{
                      warehouse.address.addressNumber
                        ? ", " + warehouse.address.addressNumber
                        : null
                    }}, {{ warehouse.address.city }} ({{
                      warehouse.address.province
                    }})
                  </div>
                </v-card-text>
                <v-spacer />
                <v-card-actions class="justify-end">
                  <v-btn
                    outlined
                    class="black--text"
                    :to="{
                      name: 'WarehouseDetail',
                      params: { slug: warehouse.slug }
                    }"
                    >Vai alla scheda negozio
                  </v-btn>
                </v-card-actions>
              </v-card>
            </swiper-slide>
          </swiper>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<style lang="scss">
.warehouse-detail {
  .warehouse-title {
    font-size: 2.6rem;
  }
  .text-no-decoration {
    text-decoration: none;
  }
  h1 {
    z-index: 2;
    position: relative;
    margin: 0;
    padding: 0;
    margin-left: 8%;

    span {
      font-weight: 400;
      position: relative;
      font-size: 16px;
      top: -4px;
    }
  }

  .go-back {
    text-decoration: none;
    cursor: pointer;
  }

  .custom-divider {
    border-width: 1px;
    border-color: var(--v-primary-base);
    position: absolute;
    width: 100vw;
    top: 36px;
    z-index: 1;
  }

  padding: 15px 0;

  .warehouse-list-list {
    height: 380px;
    overflow-y: auto;
  }

  .warehouse-detail-column {
    position: relative;

    .channels-container {
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }

  .v-chip__content {
    //padding: 0 16px;
    @media (max-width: 375px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .pdv-service-img {
    margin: 0 4px;
    width: 50px;
  }

  .warehouse-gallery {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>
<script>
import AddressService from "~/service/addressService";
import WarehouseHours from "@/components/warehouse/WarehouseHours.vue";
import isOpenRightNow from "@/components/warehouse/isOpenRightNow.vue";
import clickHandler from "~/mixins/clickHandler";
import downloadDocument from "~/mixins/downloadDocument";
import FlyerSlider from "@/components/shopfully/FlyerSlider.vue";
import toNumber from "lodash/toNumber";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "WarehouseDetail",
  components: { WarehouseHours, isOpenRightNow, FlyerSlider },
  data() {
    return {
      disableFlyer: false,
      warehouse: null,
      serviceGroupId: 1,
      channelGroupId: 2,
      services: null,
      channels: null,
      nearWarehouses: null,
      swiperOptions: {
        spaceBetween: 20,
        autoplay: {
          disableOnInteraction: true
        },
        watchOverflow: true,
        loop: false,
        pagination: {
          el: `.banner-pagination-near-wh`,
          clickable: true
        },
        navigation: {
          prevEl: `.banner-slider-prev-near-wh`,
          nextEl: `.banner-slider-next-near-wh`
        },
        breakpoints: {
          0: {
            slidesPerView: 1
          },
          600: {
            slidesPerView: 2
          },
          960: {
            slidesPerView: 3
          },
          1264: {
            slidesPerView: 3
          },
          1904: {
            slidesPerView: 4
          }
        }
      }
    };
  },
  mixins: [clickHandler, downloadDocument],
  computed: {
    today() {
      return this.$dayjs().day();
    },
    google: gmapApi,
    mapWidth() {
      return "auto";
    },
    mapHeight() {
      return "330px";
    }
  },
  methods: {
    async findWarehouse(slug) {
      let data = null;
      if (toNumber(slug)) {
        data = await AddressService.findWarehouse({
          parent_warehouse_id: toNumber(slug)
        });
      } else {
        data = await AddressService.findWarehouse({
          parent_warehouse_slug: slug
        });
      }
      /**
       * * IMPORTANT: SUP-35121 - Hide Volantino section for wId = 78,79,103
       * TODO: Remove this const warehouseIdentifier, disableFlyer. Remove disableFlyer also from FlyerSlider.vue
       *
       */
      const warehouseIdentifier = data.warehouses[0].warehouseId;
      (warehouseIdentifier && warehouseIdentifier == 79) ||
      warehouseIdentifier == 78 ||
      warehouseIdentifier == 103
        ? (this.disableFlyer = true)
        : (this.disableFlyer = false);

      if (data.warehouses) {
        this.warehouse = data.warehouses[0];

        this.services = this.warehouse.warehouseClass.reduce(
          (accumulator, element) => {
            const groupId = element.warehouseClassGroup.warehouseClassGroupId;

            const foundGroup = accumulator.find(
              group => group.warehouseClassGroupId === groupId
            );
            if (foundGroup) {
              foundGroup.services.push({
                name: element.name,
                description: element.description,
                active: element.active,
                cod_int: element.cod_int,
                iconSource: element.iconSource,
                priority: element.priority
              });
            } else {
              accumulator.push({
                warehouseClassGroupId: groupId,
                name: element.warehouseClassGroup.name,
                services: [
                  {
                    name: element.name,
                    description: element.description,
                    active: element.active,
                    cod_int: element.cod_int,
                    iconSource: element.iconSource,
                    priority: element.priority
                  }
                ]
              });
            }

            return accumulator;
          },
          []
        );

        this.channels = this.warehouse.warehouseClass.filter(
          wc =>
            wc.warehouseClassGroup.warehouseClassGroupId === this.channelGroupId
        );
      }
    },
    async findNearWarehouse(warehouse, meters) {
      let searchParams = {};
      searchParams["parent_latitude"] = warehouse.address.latitude;
      searchParams["parent_longitude"] = warehouse.address.longitude;
      searchParams["parent_distance"] = meters;
      let data = await AddressService.findWarehouse(searchParams);
      this.nearWarehouses = data.warehouses.filter(
        wh => wh.warehouseId != this.warehouse.warehouseId
      );
    },
    findLink(target, i) {
      if (target && target.hostname) {
        return target;
      } else if (i < 5) {
        return this.findLink(target.parentElement, ++i);
      } else {
        return null;
      }
    },

    onLinkClick(e) {
      var url = e.currentTarget.href;
      if (!url) {
        let target = this.findLink(e.target, 1);
        url = target.href;
      }
      let fileName = "";
      this.$nextTick(() => {
        console.log("EVENT:", e);

        if (this.$platform_is_cordova && !url) {
          url = e.currentTarget.getElementsByTagName("a")[0].href;
          console.log("MY URL", url);
        }
        if (this.$platform_is_cordova && url.endsWith(".pdf")) {
          fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
          fileName = fileName.substring(0, fileName.length - 4);
          this.handleReceiptDownload(url, fileName);
        } else if (e.target.className.includes("cookiebot-widget")) {
          if (window.Cookiebot) {
            window.Cookiebot.show();
          }
        } else {
          this.clicked(e);
        }
      });
    }
  },
  async created() {
    await this.findWarehouse(this.$route.params.slug);
    await this.findNearWarehouse(this.warehouse, 20000);
  },
  watch: {
    $route() {
      this.findWarehouse(this.$route.params.slug);
    }
  },
  metaInfo() {
    //console.log("FFFFFF", this.warehouse);
    let addr = "";
    if (this.warehouse) {
      addr = this.warehouse.address.address1;
      if (this.warehouse.address.addressNumber) {
        addr += `,  ${this.warehouse.address.addressNumber}`;
      }
      addr += ` ${this.warehouse.address.city} (${this.warehouse.address.province})`;
    }
    const title = this.warehouse
      ? `${this.warehouse.address.addressName} - Volantino, orari, offerte e servizi`
      : "Alí Supermercati - Volantino, orari, offerte e servizi";
    const description = this.warehouse
      ? `Scopri gli orari di apertura, i servizi, i reparti del punto vendita Alí ${this.warehouse.address.addressName}. ${addr}`
      : "Scopri gli orari di apertura, i servizi, i reparti del punto vendita Alí";
    return {
      title: title,
      link: [
        {
          rel: "canonical",
          href: `https://${window.location.host}${this.$route.path}`
        }
      ],
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        // Open Graph Metas
        {
          vmid: "og:locale",
          property: "og:locale",
          content: "it_IT"
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: title
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "website"
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: global.config?.defaultPageName
        },
        // Twitter Metas
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: title
        },
        {
          vmid: "twitter:image",
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "twitter:url",
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
